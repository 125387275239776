export default {
  data: function data() {
    return {
      list: [{
        title: "成本可控制",
        c: "控制生产的每个节点，产品包装、原料、生产、仓储、渠道、物流",
        icon: require("@/assets/hotel/c/icon1.png")
      }, {
        title: "责任可追究",
        c: "每个环节了如指掌，企业管理更方便，消费者查询更清晰",
        icon: require("@/assets/hotel/c/icon2.png")
      }, {
        title: "用户可信赖",
        c: "扫码展示当前商品追溯信息，为品牌做保障，增加用户信赖",
        icon: require("@/assets/hotel/c/icon3.png")
      }, {
        title: "政策可把握",
        c: "满足国家政策需求，供应链溯源系统按国家工信部要求开发实施完成",
        icon: require("@/assets/hotel/c/icon4.png")
      }]
    };
  }
};