import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_top = _resolveComponent("top");

  var _component_hotel_a = _resolveComponent("hotel-a");

  var _component_hotel_c = _resolveComponent("hotel-c");

  var _component_hotel_b = _resolveComponent("hotel-b");

  var _component_common_swiper2 = _resolveComponent("common-swiper2");

  var _component_hotel_d = _resolveComponent("hotel-d");

  var _component_common_recommend = _resolveComponent("common-recommend");

  var _component_common_footer1 = _resolveComponent("common-footer1");

  var _component_common_back_top = _resolveComponent("common-back-top");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_top), _createVNode(_component_hotel_a), _createVNode(_component_hotel_c), _createVNode(_component_hotel_b), _createVNode(_component_common_swiper2, {
    list: $data.list
  }, null, 8, ["list"]), _createVNode(_component_hotel_d), _createVNode(_component_common_recommend, {
    title: $data.recommend.title,
    list: $data.recommend.list
  }, null, 8, ["title", "list"]), _createElementVNode("div", {
    style: _normalizeStyle({
      margin: '0 0 .4rem'
    })
  }, null, 4), _createVNode(_component_common_footer1), _createVNode(_component_common_back_top)]);
}